import '../../styles/press.scss';
export function PR20240909() {
    return (
        <>


                            <div className="col-4 col-lg-2 pr-date">Sept 9, 2024</div>
                            <p className="pr-intro pr-headline">TriArm Therapeutics Appoints Douglas E. Williams, Ph.D., <span className="nowrap">as Chief Executive Officer</span></p>
                                <p>
                                SAN MATEO, California, September 9, 2024 – TriArm Therapeutics (“TriArm” or the “Company”), a clinical-stage, next-generation cell therapy company leveraging its global presence and core non-viral platform to advance and democratize CAR-T therapy for patients worldwide, today announced the appointment of Douglas E. Williams, Ph.D. as Chief Executive Officer (CEO).
                                </p><p>
                                “Doug is an accomplished biopharmaceutical veteran with over 30 years of cross-functional drug development expertise, senior corporate leadership experience, proven capital markets prowess and strategic deal-making savvy, as well as a strong track record of delivering paradigm-shifting products to patients, including several blockbusters such as Enbrel, Tecfidera, and Spinraza,” 
                                said James Huang, Founder and Executive Chairman of the Board of Directors of TriArm. “We are beyond delighted to welcome Doug as CEO to lead TriArm through our next phase of growth and have him at the helm as we carry out our mission of advancing safer, more effective, and lower-cost CAR-T products for patients around the world.”
                                </p><p>
                                “TriArm is an emerging force in the development of next-generation cell therapies, and I am excited to join the Company at such a pivotal time in its journey,” said Dr. Williams. “TriArm’s foundational non-viral platform, unique R&D strategy and dedication to democratizing CAR-T therapy create the potential to address fundamental limitations of today’s commercial CAR-T armamentarium 
                                that have precluded a more profound global impact, including manufacturing constraints, safety concerns and a limited number of approved indications. I look forward to working with the talented team at TriArm to build upon the Company’s impressive progress to date, driving our differentiated therapies toward regulatory approval and ultimately to the patients who need them most.”
                                </p><p>
                                Most recently, Dr. Williams served as the President of Research and Development at Sana Biotechnology, where he led efforts across the company’s pipeline of gene and cell-based therapies. Prior to joining Sana, he was the founding Chief Executive Officer of Codiak BioSciences, where he led the company through several private financing rounds as well as a successful IPO. Previously, 
                                Dr. Williams served as Executive Vice President of Research and Development at Biogen, where he played key roles in the development of novel treatments for neurological disorders, including Spinraza<span className="super">&reg;</span> and Tecfidera<span className="super">&reg;</span>. He joined Biogen from ZymoGenetics, where he was Chief Executive Officer and a member of the Board of Directors, shepherding the company through its acquisition by Bristol Myers Squibb 
                                for $985 million. Before this, he served as Chief Scientific Officer and Executive Vice President of Research and Development at Seattle Genetics and Senior Vice President and Washington Site Leader at Amgen following the company’s strategic acquisition of Immunex for $16 billion. He began his career at Immunex where he served in a series of scientific and senior leadership positions over the course 
                                of a decade. Dr. Williams has served on the board of numerous biotechnology companies including Ovid Therapeutics, Ironwood Pharmaceuticals and Regulus Therapeutics, and is currently Chairman of the Board of AC Immune SA. He holds a Ph.D. from the State University of New York at Buffalo, Roswell Park Division, and completed a postdoctoral fellowship at Indiana University School of Medicine.
                                </p>

                                <p>
                                <b>About TriArm Therapeutics</b><br/>
                                TriArm Therapeutics is a clinical-stage cell therapy company leveraging its global presence and core non-viral platform to democratize CAR-T therapy for patients worldwide. Headquartered in San Mateo, California with R&D, manufacturing and clinical operations hubs across Europe and Asia, TriArm is executing on its mission to enable better, safer and more accessible CAR-T products through a four-pronged approach centered around the company’s 
                                (1) Fast-in-Time™ (FIT™) non-viral platform capable of CAR-T manufacture in two days, (2) straightforwardly differentiated programs aimed at clinically- and/or commercially-validated targets, (3) rapid and efficient clinical translation and de-risking process via investigator-initiated trials (IITs), and (4) focus on delivering real benefit to CAR-T eligible patients currently marginalized by manufacturability issues and prohibitive cost tied to 
                                conventional CAR-T programs. TriArm was founded in 2019 by a team of pharmaceutical veterans with proven drug development records, and is supported by a top-notch board, leading scientific and clinical advisors, and long-term investors including Panacea Venture, MSD Capital, and Lombard Odier. Learn more at www.triarm.com.
                                </p>
                                <p>
                                <b>Investor Contact:</b><br/>
                                Christina Tartaglia<br/>
                                Precision AQ<br/>
                                <b>212-362-1200</b><br/>
                                <a href="mailto:christina.tartaglia@precisionaq.com">christina.tartaglia@precisionaq.com</a>
                                </p>
 
        </>
    )
}