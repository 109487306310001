import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import News from './pages/News';
import PressRelease from './pages/PressRelease';
import PressReleaseShell from './pages/PressReleaseShell';
import Privacy from './pages/Privacy';
import ErrorNotFound from './pages/ErrorNotFound';
import {PR20240415} from './press-releases/2024-04-15/index.js';
import {PR20240909} from './press-releases/2024-09-09/index.js';


import './styles/index.scss';

function App() {

  return (

  
  <main>
  <HelmetProvider>
  <Helmet>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap" rel="stylesheet"></link>
  </Helmet>
  </HelmetProvider>
  <Router>
  <Routes>
      <Route index element={<Home />} />
      <Route path='/home'  element={<Home />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/news' element={<News />} />
      <Route path='/news/pr' element={<PressRelease />} />
      <Route path='/error-404' element={<ErrorNotFound />} />
      <Route path="/news/press-release/:prId" element={<PressReleaseShell />} />
      <Route path="/PR20240415" element={<PR20240415 />} />
      <Route path="/PR20240909" element={<PR20240909 />} />
      <Route path='*' element={<ErrorNotFound />} />
  </Routes>
  </Router>
  </main>
  );
}

export default App;
