import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Collapse, Popover, Toast, Tooltip, Alert, Modal, Dropdown, NavBar, ScrollSpy } from 'bootstrap';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
