import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const handleCookieOkClick = (e) => {
       // sets cookie to signal acceptance of cookie disclaimer and suppress its display
       var expires;
       document.getElementById('cookie-disclaimer').style.display = 'none';
       if (Date()) {
            var date = new Date();
            date.setTime(date.getTime()+(30*24*60*60*1000));  //30 days
            expires = "; expires="+date.toUTCString();
        } else {
            expires="";
        }
       document.cookie = "triarmCookieConsent=ok" + ";" + expires + ";path=/";
       return false;
}
export default function index() {
    return (
        <>
        <div id="triarm-body-container" data-bs-spy="scroll" data-bs-target="#navbarNav" data-bs-root-margin="-10% 0px -46%" data-bs-smooth-scroll="true" tabIndex="0" style={{position : 'relative', height: '100%'}}>

        <header>
             <div className="container"><div className="row"><div className="col-12">
             <Navbar />
            </div></div></div>
        </header>


        <div id="triarm-main-container">
        <section id="about">
                <div className="container jumbo">
                    <div className="row hide-lg hero-mobile">
                        <div className="col-7"><h1>FORGING THE BEST CELL THERAPIES. <strong><i>FOR&nbsp;EVERYONE</i></strong>.</h1></div>
                        <div className="col-5"><img id="hero-logo" src="/assets/images/hero-logo-m.png" alt="TriArm logo "/></div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-8 jumbo-text-block">
                            <h1 className="tight">FORGING THE BEST CELL THERAPIES. <strong><i>FOR&nbsp;EVERYONE</i></strong>.</h1>
                            {/*<p>
                            TriArm is a clinical-stage cell therapy company leveraging a global presence and core non-viral platform to democratize curative CAR-T therapy for patients worldwide. Led by <br className="hide-over"/>a team of leading pharmaceutical veterans, our mission <br className="hide-over"/>is to enable better, 
                            safer and more accessible <br className="hide-over"/>CAR-T products. We are focused on delivering <br className="hide-over"/>real benefit to CAR-T therapy eligible patients <br className="hide-over"/>that are currently
                            unable to access conventional <br className="hide-over"/>CAR-T programs due to manufacturability <br className="hide-over"/>issues and prohibitive costs. 
    </p> */}
                            <p>
                            TriArm is a clinical-stage cell therapy company leveraging its global presence and core non-viral platform to democratize curative CAR-T therapy for patients worldwide. 
                            Stay tuned as we execute on our mission to deliver better, safer, and more accessible CAR-T products to patients currently marginalized by manufacturability constraints and prohibitive cost tied to conventional <span className="nowrap">CAR-T programs</span>.
                            </p>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
        </section>
        <section id="our-approach-ctr">
            <div className="container"> 
                <div className="row">
                    <div className="col px-lg-0 mx-auto">
                        <div id="our-approach" name="our-approach">
                        {/*TriArm’s Four-Pronged Approach to Democratization of <span className="nowrap">Curative CAR-T Therapy</span> */}
                        <h2>OUR FOUR-PRONGED APPROACH TO DEMOCRATIZING <span className="nowrap">CURATIVE CAR-T THERAPY</span></h2>
                        <div className="ctr-approach hide-sm"><img src="/assets/images/approach.png" className="img-fluid" alt="" /></div>

                        <div id="mission-list-ctr" className="hide-lg">
                            <ul className="mission-list">
                                <li><span className="color-brand-primary bold">DISRUPTIVE NON-VIRAL CAR-T PLATFORM: </span> 
                                Tackles manufacturing constraints of today’s armamentarium that marginalize a majority of patients otherwise eligible for CAR-T globally  
                                </li><li><span className="color-brand-primary bold">STRAIGHTFORWARDLY DIFFERENTIATED PROGRAMS: </span> 
                                Aimed at compelling, clinically- and/or commercially-validated targets, each with best-in class potential, high approvability and solid IP
                                </li><li className="longer"><span className="color-brand-primary bold">EFFICIENT CLINICAL TRANSLATION AND DE-RISKING PROCESS: </span> 
                                Investigator-initiated trials (IITs) to be initiated in ex-US regions where CAR-T is largely inaccessible, enabling expeditious, cost-efficient clinical validation that de-risks costly US clinical trials
                                </li><li><span className="color-brand-primary bold">FOCUS ON DELIVERING REAL PRODUCTS: </span> 
                                Goal of driving shareholder value and in turn enable sustainable manufacturing scale and market expansion to benefit of more patients in need worldwide
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>  
                </div>     
            </div>
        </section>

        <section id="tech">
            <div className="container"> 
                <div className="row">
                    <div className="col-12">
                        <hr className="primary"/>
                        <h2>RESHAPING CELL THERAPY</h2><a id="science" name="science" />
                        <h3>Core Platform</h3>
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-5 pe-md-0 tech-col">
                        <div className="tech-def">
                            <div>Feasible, efficient, <span className="nowrap">and reproducible</span> <span className="nowrap">NON-VIRAL process</span></div>
                        </div>
                    </div>
                    <div className="col-md-1 px-0 hide-sm tech-sep"><img src="/assets/images/separator-vert.png" alt=""/></div>
                    <div className="col-md-6 ps-md-0 tech-col">
                        <div className="tech-hdr">FIT<span className="super">&trade;</span> (Fast-In-Time)</div>
                        <ul>
                            <li>Innovative non-viral manufacturing process</li>
                            <li>Rapid vein-to-vein turnaround in several days</li>
                            <li>Enhanced yield and stemness at a fraction of the cost</li>
                        </ul> 

                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-lg-11 mx-auto">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-lg-11 mx-auto">
                        <h3>Enhancive Technologies</h3>
                    </div>
                </div>
                <div className="row tech-3-col">
                    <div className="col-md-4">
                        <h4 className="tech-hdr">FlexTune<span className="super">&trade;</span></h4>
                        <p>Decoupled antigen binding and CAR signaling to improve polyclonality, persistence and safety</p>
                        <ul>
                            <li>Adaptors (OppoTAGs) are used to optimize CAR-T activity timing, potency and diversity</li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                    <h4 className="tech-hdr">CD30+ FineTune<span className="super">&trade;</span></h4>
                        <p>Novel CD30+ scFV amplifies rapidity and magnitude of CAR-T expansion</p>
                        <ul>
                            <li>Proprietary manufacturing and CAR design to prevent fratricide and produce more youthful, stem-like CAR-T cells</li>
                        </ul>                    
                    </div>
                    <div className="col-md-4">
                    <h4 className="tech-hdr">CD3ε FlowTune<span className="super">&trade;</span></h4>
                        <p>Breakthrough innovation, CD3ε modulator, for gentler cytokine release profile</p>
                        <ul>
                            <li>Enhanced therapeutic index via restoring the natural signaling of TCR in a self-restrained mechanism</li>
                            <li>Affords utility in various autoimmune diseases regardless of treatment chronicity</li>
                        </ul>                  
                    </div>
                </div>     

            </div>
        </section>

        <section id="unique-r-and-d">
            <div className="container">
                <div className="row">
                        <div className="col-12">
                            <hr className="primary"/>
                            <h2>UNIQUE R&amp;D STRATEGY</h2> <a id="how-we-differ" name="how-we-differ" />
                            <h3>Allows for rapid and cost-effective clinical validation to get better products to more patients&nbsp;sooner</h3>
                            <img id="usp" className="resp hide-sm" src="/assets/images/unique-r-and-d.png" alt="How we differ" />
                            <img id="usp-m" className="resp hide-lg" src="/assets/images/unique-r-and-d-m.png" alt="How we differ" />
                        </div>
                </div>
            </div>

        </section>

        <section id="pipeline-ctr">
            <div className="container">
                <div  className="row">
                    <div className="col-12 mx-auto">
                        <hr className="primary"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-10 col-xl-8 mx-auto">
                        <h2>PIPELINE</h2> <a id="pipeline" name="pipeline" />
                    </div>
                </div>
                <div  className="row">
                    <div className="col-12 mx-auto">
                        <img className="resp" src="/assets/images/pipeline.png" alt="TriArm technologies in CAR-T cell therapy pipeline" />
                    </div>
                </div>
                <div className="row"><div className="col"> 
                    <a name="contact-anchor" id="contact-anchor"> </a>
                </div></div>
            </div>
            </section>

            <section id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <a className="btn btn-triarm-cta mx-auto" href="mailto:sternir-triarm@precisionaq.com" role="button">CONTACT US</a>
                        </div>
                    </div>
                    <div id="jumpToTop"><img src="/assets/images/caret-right-large.png" alt="" />&nbsp;&nbsp;<a href="#">BACK TO TOP</a></div>
                </div>
            </section>

        </div>

        <Footer />

        <nav id="cookie-disclaimer" className="navbar fixed-bottom bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10">
                    <p style={{color: 'white'}}>By continuing to use our website, you agree to our use of cookies to give you the best experience, 
                        analyze site analytics, and assist in our marketing efforts. For more information, please review our <a href="/privacy" target="_self">privacy&nbsp;policy</a>.
                    </p>
                    </div>
                    <div className="col-6 col-md-2 mx-auto d-flex flex-column justify-content-center">
                        <a className="btn btn-triarm-ok" href="#" role="button" onClick={handleCookieOkClick}>OK</a>
                    </div>
                </div>
            </div>
        </nav>

        </div>
        </>
    )
}