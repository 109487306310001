export default function Footer() {
    return (
        <div className="container-fluid tri-no-x-pad">
        <footer>
            <div className="row">
                <div className="col-6 copyright"><div>2024 &copy; TriArm <span className="hide-xs">| </span><span className="location">San Mateo, CA</span></div></div><div className="col-6 privacy"><div><a href="/privacy">Privacy Policy</a> <a href="mailto:sternir-triarm@precisionaq.com">Contact Us</a></div></div>
            </div>
        </footer>
        </div>
    )
}   