import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import parse from 'html-react-parser';
import html_about_triarm from '../about-triarm.html';

const handleCookieOkClick = (e) => {
       // sets cookie to signal acceptance of cookie disclaimer and suppress its display
       var expires;
       document.getElementById('cookie-disclaimer').style.display = 'none';
       if (Date()) {
            var date = new Date();
            date.setTime(date.getTime()+(30*24*60*60*1000));  //30 days
            expires = "; expires="+date.toUTCString();
        } else {
            expires="";
        }
       document.cookie = "triarmCookieConsent=ok" + ";" + expires + ";path=/";
       return false;
}

export default function pressrelease() {
    return (
        <>
        <div id="triarm-body-container" data-bs-smooth-scroll="true" tabIndex="0" style={{position : 'relative', height: '100%'}}>

        <header>
             <div className="container"><div className="row"><div className="col-12">
             <Navbar page={'news'}/>
            </div></div></div>
        </header>


        <div id="triarm-main-container" className="news">
        <section id="press-release">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="intro-copy">
                            <h2>Press Release</h2>
                        </div>
                        <div>
                            <p>
                                <span className="pr-date">April 15, 2024 &ndash;</span> Aut sint sunt et voluptatem placeat ut deserunt voluptas. Est quia aliquid est distinctio praesentium et accusantium corrupti sit nemo ipsa. Lorem ipsum dolor sit amet.
                            </p>
                            <p>
                                Et obcaecati reprehenderit et rerum nesciunt aut facere repellendus ut ratione aliquam. Eos Quis mollitia ut vitae provident qui dolorum libero. Qui beatae assumenda qui illum temporibus et autem ipsum aut officia odit quo officia explicabo 
                                et explicabo nulla sed distinctio laboriosam! Ut earum tenetur sed amet repellendus ex iste necessitatibus est earum adipisci. Est vero voluptates sit velit sunt hic cumque minima ab perferendis dolor qui voluptas eius?
                            </p>
                            <p>
                                Ex similique ratione in laboriosam placeat ut sint similique eum enim voluptas. Quo sapiente perspiciatis et distinctio eaque aut obcaecati ipsa eos ipsum perferendis. 
                                Aut explicabo placeat rem vitae cupiditate non nostrum odio aut labore laborum ad perferendis internos. Ut maiores enim quo harum quas et quia aspernatur cum corporis galisum et distinctio dolores et iure nulla. Eos dolorem saepe ut 
                                voluptatem facilis id accusamus nihil. Aut nesciunt dolores ab consequatur dignissimos et autem eius est consectetur voluptas. Aut quibusdam eius vel error reiciendis eum harum sapiente in sapiente rerum et odit ipsam. Ab pariatur tenetur 
                                ut repellat amet qui nihil veniam ab praesentium numquam aut itaque molestiae aut error velit qui assumenda odio. Et tempore omnis in natus asperiores eos accusamus autem non excepturi eaque id molestias modi 33 aperiam accusamus vel 
                                optio sint.
                            </p>
                            {  parse(html_about_triarm ) }
                        </div>
                    </div>  
                </div>
            </div>
        </section>
        <section id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <a className="btn btn-triarm-cta mx-auto" href="mailto:sternir-triarm@precisionaq.com" role="button">EMAIL US</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer />

        <nav id="cookie-disclaimer" className="navbar fixed-bottom bg-dark">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10">
                    <p style={{color: 'white'}}>By continuing to use our website, you agree to our use of cookies to give you the best experience, 
                        analyze site analytics, and assist in our marketing efforts. For more information, please review our <a href="/privacy" target="_self">privacy&nbsp;policy</a>.
                    </p>
                    </div>
                    <div className="col-6 col-md-2 mx-auto d-flex flex-column justify-content-center">
                        <a className="btn btn-triarm-ok" href="#" role="button" onClick={handleCookieOkClick}>OK</a>
                    </div>
                </div>
            </div>
        </nav>

        </div>
        </>
    )
}