import Footer from "../components/Footer";

export default function ErrorNotFound() {
    return (
        <div id="error404" className="container">
        <div className="row">
            <div className="col-12">

        <a className="navbar-brand" href="https://triarm.com" target="_self"><img src="/assets/images/triarm-logo.png" alt="TriArm Therapeutics"/></a>  
        <div className="header">
        <h1>Error: Not Found</h1>  <a href="https://triarm.com" target="_self" className="btn btn-triarm-cta" role="button">BACK TO MAIN SITE</a>
        </div>

        <p>&nbsp;</p><p>&nbsp;</p>
        <p>The page you requested could not be found.</p>
        <p>&nbsp;</p><p>&nbsp;</p>
        <div style={{marginBottom: '360px'}}></div>

        </div>  
        </div>
        <Footer />
        </div>
    )
}