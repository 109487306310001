import React from 'react';
import { useParams } from "react-router-dom"
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import parse from 'html-react-parser';
import html_about_triarm from '../about-triarm.html';

const handleCookieOkClick = (e) => {
       // sets cookie to signal acceptance of cookie disclaimer and suppress its display
       var expires;
       document.getElementById('cookie-disclaimer').style.display = 'none';
       if (Date()) {
            var date = new Date();
            date.setTime(date.getTime()+(30*24*60*60*1000));  //30 days
            expires = "; expires="+date.toUTCString();
        } else {
            expires="";
        }
       document.cookie = "triarmCookieConsent=ok" + ";" + expires + ";path=/";
       return false;
}

const iFrameLoaded = (timeout) => {
    return new Promise((resolve, reject) => {
        const startTime = Date.now();
        function checkiFrame() {
            //console.log('checking iframe');
            let ip = document.getElementById("ifPress"); 
            if (ip.contentWindow && ip.contentWindow.document && ip.contentWindow.document.body && ip.contentWindow.document.body.scrollHeight > 0) {
                resolve(ip.contentWindow.document.body.scrollHeight);
            } else if (Date.now() - startTime > timeout) {
                reject('timed out');
            }
            else {
                setTimeout(checkiFrame, 60)
            };
        }
        checkiFrame();
    });
}

function Iframe(props) {
return <iframe id={props.id} name={props.name} title={props.title} src={props.src} height={props.height} width={props.width} scrolling="no"></iframe>;
}

export default function PressReleaseShell() {
    const { prId } = useParams();
    let includePath = '/PR' + prId;

    let ignoreRerender = false;
    React.useEffect(() => {
        
    if (!ignoreRerender) {
        var iPress = document.getElementById("ifPress"); 
        var frame = window.frames["ifPress"].document;

        // Inject neededCSS into iframe
        var cssBlock = document.createElement("style");
        cssBlock.type = "text/css"; 
        cssBlock.innerText = 'span.pr-date, .pr-date{font-weight: bold;color: #0071BC;} ' + 
        'p {font-family:"Open Sans","Helvetica Neue",sans-serif;font-size:1rem;line-height:1.2;} ' + 
          'li {font-size:.9rem;line-height:1.19;} ' + 
          'a {color: #0071BC;}';
       // frame.head.appendChild(cssBlock);

        // Adjust the iframe height onload event 
        iPress.onload = async function() 
        { 
          // set the height of the iframe to the height of the iframe content 
        var ifHeight = 0;
        try {
            ifHeight =  await iFrameLoaded(3000);
        } catch (e){
            console.log("iFrame check failed");
        }   
          iPress.style.height =  iPress.contentWindow.document.body.scrollHeight + 'px';  //totalHeight + 'px'; 
        } 
    }

        return () => { ignoreRerender = true; }
      }, []);
    return (
        <>
<div id="triarm-body-container" data-bs-smooth-scroll="true" tabIndex="0" style={{position : 'relative', height: '100%'}}>

<header>
     <div className="container"><div className="row"><div className="col-12">
     <Navbar page={'news'}/>
    </div></div></div>
</header>


<div id="triarm-main-container" className="news">
<section id="press-release">
    <div className="container">
        <div className="row">
            <div className="col intro-copy-ctr">
                <div className="intro-copy">
                    <h2>Press Release</h2>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div>
                    <Iframe src={includePath} width="100%" id="ifPress" name="ifPress" title="Press Release Content"></Iframe>
                </div>
            </div>  
        </div>
    </div>
</section>
<section id="contact">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <a className="btn btn-triarm-cta mx-auto" href="mailto:sternir-triarm@precisionaq.com" role="button">CONTACT US</a>
                </div>
            </div>
        </div>
    </section>
</div>

<Footer />

<nav id="cookie-disclaimer" className="navbar fixed-bottom bg-dark">
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-10">
            <p style={{color: 'white'}}>By continuing to use our website, you agree to our use of cookies to give you the best experience, 
                analyze site analytics, and assist in our marketing efforts. For more information, please review our <a href="/privacy" target="_self">privacy&nbsp;policy</a>.
            </p>
            </div>
            <div className="col-6 col-md-2 mx-auto d-flex flex-column justify-content-center">
                <a className="btn btn-triarm-ok" href="#" role="button" onClick={handleCookieOkClick}>OK</a>
            </div>
        </div>
    </div>
</nav>

</div>
        </>
    )
}