import Footer from "../components/Footer";

export default function privacy() {
    return (
        <div id="privacy" class="container">
        <div class="row">
            <div class="col-12">

        <a class="navbar-brand" href="https://triarm.com" target="_self"><img src="/assets/images/triarm-logo.png" alt="TriArm Therapeutics"/></a>  
        <div class="header">
        <h1>Privacy Policy</h1>  <a href="/" target="_self" class="btn btn-triarm-cta" role="button">BACK TO MAIN SITE</a>
        </div>

        <p>Last updated: 11/30/23</p>
<p>At TriArm Therapeutics ("we," "us," "our"), we are committed to protecting your privacy. This Privacy Policy applies to the website www.TriArm.com (the "Service") and governs our data collection, processing, and usage practices. It also describes your choices regarding use, access, and correction of your personal information.</p>
<p>Please read the policy carefully before using the Service.</p>
<h3>No Data Collection</h3>
<p>We firmly believe in the importance of your privacy. Therefore, we do not collect any personal data through our Service. Our Service is designed to provide you with information about our products/services without the need to collect personal information from you.</p>
<h3>Third-Party Services</h3>
<p>While our Service does not collect any data, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.</p>
<h3>Changes to This Privacy Policy</h3>
<p>We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you by means of a notice on this site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
<h3>Contact Us</h3>
<p>If you have any questions about this Privacy Policy, please contact us at:</p>
<p>Mike Chen, GC</p>
<p>mike.chen@triarmbio.com</p>
<p>By using our Service, you acknowledge that you have read and agree to this Privacy Policy.</p>
<p>&nbsp;</p>
<div style={{textAlign:'center'}}>
<a href="/" target="_self" class="btn btn-triarm-cta" style={{marginTop :'0', marginLeft : '0'}} role="button">BACK TO MAIN SITE</a>
</div>
<p>&nbsp;</p>

        </div>  
        </div>
        <Footer />
        </div>
    )
}