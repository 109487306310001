import '../../styles/press.scss';
export function PR20240415() {
    return (
        <>
                           <p>
                                <span className="pr-date">April 15, 2024 &ndash;</span> Aut sint sunt et voluptatem placeat ut deserunt voluptas. Est quia aliquid est distinctio praesentium et accusantium corrupti sit nemo ipsa. Lorem ipsum dolor sit amet.
                            </p>
                            <p>
                                Et obcaecati reprehenderit et rerum nesciunt aut facere repellendus ut ratione aliquam. Eos Quis mollitia ut vitae provident qui dolorum libero. Qui beatae assumenda qui illum temporibus et autem ipsum aut officia odit quo officia explicabo 
                                et explicabo nulla sed distinctio laboriosam! Ut earum tenetur sed amet repellendus ex iste necessitatibus est earum adipisci. Est vero voluptates sit velit sunt hic cumque minima ab perferendis dolor qui voluptas eius?
                            </p>
                            <p>
                                Ex similique ratione in laboriosam placeat ut sint similique eum enim voluptas. Quo sapiente perspiciatis et distinctio eaque aut obcaecati ipsa eos ipsum perferendis. 
                                Aut explicabo placeat rem vitae cupiditate non nostrum odio aut labore laborum ad perferendis internos. Ut maiores enim quo harum quas et quia aspernatur cum corporis galisum et distinctio dolores et iure nulla. Eos dolorem saepe ut 
                                voluptatem facilis id accusamus nihil. Aut nesciunt dolores ab consequatur dignissimos et autem eius est consectetur voluptas. Aut quibusdam eius vel error reiciendis eum harum sapiente in sapiente rerum et odit ipsam. Ab pariatur tenetur 
                                ut repellat amet qui nihil veniam ab praesentium numquam aut itaque molestiae aut error velit qui assumenda odio. Et tempore omnis in natus asperiores eos accusamus autem non excepturi eaque id molestias modi 33 aperiam accusamus vel 
                                optio sint.
                            </p>
 
        </>
    )
}